
.r-manage-vtable-container {
    width: 100%;
    margin: 20px auto;
    text-align: center;
}

.r-manage-vtable-title {
    font-size: 1.5em;
    margin-bottom: 15px;
}

.r-manage-vtable {
    width: 100%;
    border-collapse: collapse;
    /* background-color: #2e2e2e; */
}

.r-manage-vtable-head {
    background-color: #333333;
}

.r-manage-vtable-header {
    padding: 12px 15px;
    border: 1px solid #444;
    font-weight: bold;
    color: #f0f0f0;
    text-align: center;
}

.r-manage-vtable-row:hover {
    background-color: #333333;
}

.r-manage-vtable-cell {
    padding: 12px 15px;
    border: 1px solid #444;
    text-align: center;
    color: #ddd;
}

.r-manage-vtable-cell-name {
    text-align: left;
}

.r-manage-vtable-cell-date {
    width: 20%;
}

.r-manage-vtable-title2 {
    text-align: left;
    font-size: 36px;
    color: white;
    font-weight: 700;
    margin-bottom: 30px;
}

.r-manage-tlist-btn {
    background-color: #333;
    color: #f0f0f0;
    font-family: Montserrat;
    border: none;
    padding: 10px 15px;
    margin-right: 5px;
    /* border-radius: 5px; */
    cursor: pointer;
}

.r-manage-tlist-btn:hover {
    background-color: #d71f26;
}

.r-manage-tlist-btn-active {
    background-color: #d71f26;
    color: #f0f0f0;
    font-family: Montserrat;
    border: none;
    padding: 10px 15px;
    margin-right: 5px;
    /* border-radius: 5px; */
    cursor: pointer;
}

.r-manage-roster-container {
    width: 100%;
    color: #ffffff;
    padding: 20px;
}

.r-manage-roster-container h2 {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
}


.r-manage-create-game-form label {
    margin-bottom: 5px;
    display: block;
}

.r-manage-create-game-form input {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.r-manage-create-game-form select {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.r-manage-create-game-form textarea {
    background-color: #333;
    padding: 7px;
    border: none;
    width: 100%;
    color: white;
}

.r-manage-text-center {
    text-align: center;
}

.r-manage-text-right {
    text-align: right;
}

.r-manage-text-left {
    text-align: left;
}

.r-manage-vtable-cell-name {
    text-align: left;
}

.r-manage-vtable-cell-points {
    width: 15%;
}

.r-manage-vtable-cell-15 {
    width: 15%;
}

.r-manage-filters {
    padding-bottom: 50px;
}

.r-manage-hideweapons {
    font-size: 14px;
    color: #a1a0a0;
}

.r-manage-showselected {
    font-size: 14px;
    color: #a1a0a0;
}

.r-manage-warlord-button {
    background: none !important;
    border: none;
    padding-left: 5px !important;
    text-decoration: underline;
    cursor: pointer;
    color: #f0f0f0;
    font-size: 0.7rem;
}

.r-manage-clone-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
    color: #f0f0f0;
    font-size: 0.7rem;
    padding-left: 5px !important;
}
.r-manage-remove-cloned-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
    color: #f0f0f0;
    font-size: 0.7rem;
    padding-left: 5px !important;
}

.r-manage-equipments {
    color: #a1a0a0;
}

.r-manage-warlord-mark {
    color: #d71f26;
}

.r-manage-unit-type {
    font-size: 1.5rem;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 1px;
}

.r-manage-unit-weapon-type {
    font-size: 0.8rem;
}

.r-manage-unit-name {
    font-size: 1rem;
    font-weight: bold;
}

.r-manage-unit-weapon-name {
    font-size: 0.8rem;
    padding-left: 10px;
    color: #a1a0a0;
}

.r-manage-unit-model-name {
    font-size: 0.9rem;
    padding-left: 10px;
}

.r-manage-input-number {
    width: 50px;
    padding: 3px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 10px;
    outline: none;
    background-color: #333;
    color: #f0f0f0;

}
.r-manage-input-adjustment {
    width: 300px;
    padding: 3px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 10px;
    outline: none;
    background-color: #333;
    color: #f0f0f0;
}

.r-manage-unit-select {
    padding-left: 5px;
    padding-right: 5px;
    color: #63E6BE
}

.r-manage-hideweapons-select {
    padding-left: 5px;
    padding-right: 5px;
}

.r-manage-showselected-select {
    padding-left: 5px;
    padding-right: 5px;
}

.r-manage-unit-selectbox {
    padding-left: 5px;
    padding-right: 5px;
}

.r-manage-hideweapons-selectbox {
    padding-left: 5px;
    padding-right: 5px;
}

.r-manage-showselected-selectbox {
    padding-left: 5px;
    padding-right: 5px;
}

.r-manage-summary-number {
    display: inline-block ;
}


.r-manage-name {
    text-align: center;
    color: #f0f0f0;
}

.r-manage-errors {
    color: #da3939;
}

.r-manage-summary {
    color: #f0f0f0;
    text-align: center;
    position: sticky;
    top: 0;
    background-color: #202125;
}

.blink {
    animation: blink 0.3s ease-in-out;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

.r-manage-search {
    font-size: 14px;
    color: #a1a0a0;
    position: relative;
    display: inline-block;
}

.r-manage-search-input {
    text-align: center;
    background-color: #333;
    width: 200px;
    border: 1px solid #ccc;
    font-size: 14px;
    outline: none;
    border-radius: 4px;
    color: #f0f0f0;
}

.r-manage-search-input-clear-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    color: #888;
    padding: 0;
    line-height: 1;
}

.r-manage-search-input-clear-button:hover {
    color: #ffffff;
}

.r-manage-search-input-clear-button:active {
    transform: translateY(-50%) scale(0.9);
}

.r-manage-factions {
    font-size: 14px;
    color: #a1a0a0;
}

.r-manage-factions-select {
    padding-left: 5px;
    text-align: center;
    background-color: #333;
    width: 200px;
    border: 1px solid #ccc;
    font-size: 14px;
    outline: none;
    border-radius: 4px;
    color: #f0f0f0;
}

.r-manage-unit-prices {
    font-size: 0.7rem;
    color: #a1a0a0;
    font-weight: lighter;
    display: inline-grid;
    padding-left: 10px;
}

.r-manage-adjustment-button {
    background: none !important;
    border: none;
    padding-left: 5px !important;
    text-decoration: underline;
    cursor: pointer;
    color: #f0f0f0;
    font-size: 0.7rem;
}

.r-manage-adjustments {
    padding-bottom: 20px;
    display: inline-block;
}

.r-manage-adjustment {
    padding-right: 10px;
    display: inline;
}

.r-manage-adjustment-row {
    display: flex;
    align-items: start;
}