
.g-v-game-details h2 {
  font-size: 38px;
  margin-bottom: 10px;
  text-align: center;
  color: white;
}

.g-v-buttons-container {
  padding-top: 50px;
}

.g-v-text-larger {
  font-size: 20px;
}


.g-v-gd-bold {
  font-weight: bold;
  color: white;
}

.g-v-gd-info {
  color: white;
}

.g-v-custom-hr {
  border: none;
  border-top: 2px solid #525252; /* Adjust thickness as needed */
  margin: 40px 0; /* Optional spacing */
}

.g-v-tlist-btn {
  background-color: #333;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}

.g-v-tlist-btn:hover {
  background-color: #d71f26;
}

.g-v-tlist-btn-active {
  background-color: #d71f26;
  color: #f0f0f0;
  font-family: Montserrat ;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
}
